<template>
	<LoginLayout
		v-if="showErrorScreen"
		form-title="Your link is invalid or has expired"
		portal-name="Customer Service Portal"
	>
		<template #form>
			<a @click="$router.push({ name: LOGIN })">
				Please click here to login.
			</a>
		</template>
	</LoginLayout>
</template>
<script>
import { mapActions } from 'vuex'
import LoginLayout from '../../../../../shared/layouts/login-layout.vue'
import { DATA_SUBJECT_SEARCH, LOGIN } from '../../../router/route-names.js'
export default {
	components: { LoginLayout },
	props: {
		otp: String
	},
	data () {
		return {
			showErrorScreen: false,
			LOGIN
		}
	},
	created () {
		this.tryOtpLogin()
	},
	methods: {
		...mapActions('auth', ['otpLogIn']),
		// Vue is daft and converts '+' to ' ' in the URL, so we need to convert it back to '+' before sending it to the API
		cleanOtp () {
			return this.otp.replace(/ /g, '+')
		},
		tryOtpLogin () {
			const mfaGuid = localStorage.getItem('csMfaGuid')
			return this.otpLogIn({
				otpCode: this.cleanOtp(),
				mfaGuid
			})
				.then(user => {
					if (user.token != null) {
						this.$router.push({ name: DATA_SUBJECT_SEARCH })
					}	else {
						this.showErrorScreen = true
					}
				})
				.catch(error => {
					if (error?.response?.status === 401) {
						this.$router.push({ name: 'account-locked-out' })
					}	else {
						this.showErrorScreen = true
					}
				})
		}
	}
}
</script>
